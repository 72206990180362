import { apiClient } from ".";

export const getSuggestions = async (filters = {}) => {
  try {
    const params = new URLSearchParams(filters).toString();
    const response = await apiClient.get(`/suggestion/?${params}`);
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.detail || "Un error desconocido ha ocurrido";
    throw new Error(errorMessage);
  }
};

export const getMySuggestions = async () => {
  try {
    const response = await apiClient.get("/suggestion/my");
    return response.data;
  } catch (error) {
    console.error("Error al obtener mis sugerencias:", error);
    throw new Error("No se pudieron cargar tus sugerencias.");
  }
};

export const submitSuggestion = async (data) => {
  try {
    const response = await apiClient.post("/suggestion/", data);
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.detail || "Un error desconocido ha ocurrido";
    throw new Error(errorMessage);
  }
};

export const updateSuggestion = async (id, data) => {
  try {
    const response = await apiClient.patch(`/suggestion/${id}`, data);
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.detail || "Un error desconocido ha ocurrido";
    throw new Error(errorMessage);
  }
};

export const updateSuggestionStatus = async ({ id, status }) => {
  try {
    console.log(id, status);
    
    const response = await apiClient.patch(`/suggestion/${id}`, { status });
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.detail || "Un error desconocido ha ocurrido";
    throw new Error(errorMessage);
  }
};
