import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toastNotify } from "../../utils/ToastNotify";
import { submitSuggestion } from "../../api/SuggestionService";

export const useSuggestion = () => {
  const queryClient = useQueryClient();

  const {
    mutate: mutateCreate,
    isError,
    isPending,
    error,
  } = useMutation({
    mutationFn: submitSuggestion,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["allSuggestions"] });
      toastNotify({
        type: "success",
        message: "Correcto",
        description: "Se ha enviado correctamente la sugerencia.",
      });
    },
    onError: (error) => {
      console.error("Error en la mutación", error);
      toastNotify({
        type: "error",
        message: "Error",
        description: error.message || "No se pudo enviar la sugerencia.",
      });
    },
  });
  return { mutateCreate, isError, isPending, error };
};
