import { useQuery } from "@tanstack/react-query";
import { getMySuggestions } from "../../api/SuggestionService";
import { Card, Divider, List, Space, Tag, Typography } from "antd";
import { useState } from "react";

export const SuggestionList = () => {
  const [expanded1, setExpanded1] = useState({});
//   const [expanded2, setExpanded2] = useState({});

  const { data: suggestions, isLoading } = useQuery({
    queryKey: ["mySuggestions"],
    queryFn: getMySuggestions,
  });

  return (
    <List
      loading={isLoading}
      dataSource={suggestions}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 1,
        md: 1,
        lg: 2,
        xl: 2,
        xxl: 4,
      }}
      renderItem={(item) => (
        <List.Item>
          <Card title={item.title}>
            <Typography.Paragraph
              ellipsis={{
                rows: 2,
                expandable: true,
                expanded: expanded1[item._id],
                onExpand: (_, info) =>
                  setExpanded1((prev) => ({
                    ...prev,
                    [item._id]: info.expanded,
                  })),
              }}
            >
              {item.description || "Sin comentarios"}
            </Typography.Paragraph>
            <Divider
              variant="dotted"
              style={{
                margin: ".5rem 0",
              }}
            ></Divider>
            <Space>
              <Tag bordered={false} color="processing">
                {item.category}
              </Tag>
              {/* <Divider type="vertical" className="mx-0 px-0" /> */}
              <Tag
                bordered={false}
                color={item.status === "Pendiente" ? "gold" : "green"}
              >
                {item.status}
              </Tag>
            </Space>
            {/* {!!item.admin_comments ?? (
              <Typography.Paragraph
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  expanded: expanded2[item._id],
                  onExpand: (_, info) =>
                    setExpanded2((prev) => ({
                      ...prev,
                      [item._id]: info.expanded,
                    })),
                }}
              >
                <strong>Comentarios:</strong>{" "}
                {item.admin_comments || "Sin comentarios"}
              </Typography.Paragraph>
            )} */}
          </Card>
        </List.Item>
      )}
    />
  );
};
