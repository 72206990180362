import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {  getSuggestions, updateSuggestionStatus } from "../../api/SuggestionService.jsx";
import { message } from "antd";

export const useAdminSuggestions = () => {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ["adminSuggestions"],
    queryFn: getSuggestions,
  });

  const { mutate: updateStatus } = useMutation({
    mutationFn: updateSuggestionStatus,
    onSuccess: () => {
      message.success("Estado actualizado correctamente");
      queryClient.invalidateQueries(["adminSuggestions"]);
    },
    onError: () => {
      message.error("Error al actualizar el estado");
    },
  });

  return { data, isLoading, updateStatus };
};