import * as XLSX from "xlsx";

import dayjs from "dayjs";
import moment from "moment/moment";
import "moment/locale/es-mx";
moment.locale("es-mx");

export const referralSourceToExcel = (data) => {
    if (!data?.data?.length) {
      alert("No hay datos para exportar.");
      return;
    }

    const formattedData = data.data.flatMap((item) =>
      item.items.map((user) => ({
        "Fuente de Referencia": item._id,
        "Cantidad de Usuarios": item.count,
        "Username": user.username,
        "Tutor": user.tutors_name_one || "N/A",
        "Email": user.email,
        "Teléfono": user.phone || "N/A",
        "Inscrito": user.is_inscribed ? "Sí" : "No",
        "Cantidad de Atletas": user.athletes_count,
        "Fecha de Creación": dayjs(user.created_at).format("DD/MM/YYYY"),
      }))
    );

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte Referencias");

    XLSX.writeFile(workbook, `Reporte_Referencias_${dayjs().format("YYYYMMDD_HHmmss")}.xlsx`);
  };
