import { Button, Card, Col, Form, Result, Row } from "antd";
import { FormComponent } from "../../components/FormComponent";
import { SuggestionFormFields } from "./SuggestionFormFields";
import { LoaderIconUtils } from "../../utils/LoaderIconUtils";
import { useSuggestion } from "./SuggestionMutations";
import { useState } from "react";
import { SuggestionList } from "./SuggestionList";
import "./SuggestionStyles.css";

export const SuggestionLogic = () => {
  const [form] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  let { mutateCreate, error, isError, isPending } = useSuggestion();
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      console.log("Values: ", values);
      await mutateCreate(values);
      setSubmissionSuccess(true);
      form.resetFields();
    } catch (error) {
      console.error("Error al validar los campos del formulario: ", error);
    }
  };

  if (isPending) return <LoaderIconUtils isLoading />;
  if (isError) return <div>Error: {error?.message} </div>;

  return (
    <Row
      gutter={[16, 16]}
      justify="center"
      align="top"
      className="heigth-adjust pt-4"
    >
      {!showForm ? (
        <Col span={12} style={{ textAlign: "center" }}>
          <h2 className="text-left">¡Bienvenido al Buzón de Sugerencias!</h2>
          <p className="text-left">
            Antes de enviar tu sugerencia, considera los siguientes puntos:
          </p>
          <ul style={{ textAlign: "left", display: "inline-block" }}>
            <li>
              <strong>Claridad:</strong> Describe detalladamente tu idea.
            </li>
            <li>
              <strong>Impacto:</strong> ¿Cómo mejorará la plataforma?
            </li>
            <li>
              <strong>Viabilidad:</strong> ¿Es posible implementarlo?
            </li>
            <li>
              <strong>Tipos de sugerencias:</strong>
              <ul>
                <li>
                  📌 <strong>Correcciones:</strong> Reportar errores o fallos.
                </li>
                <li>
                  🎨 <strong>Mejoras de UI:</strong> Cambios en el diseño o
                  experiencia de usuario.
                </li>
                <li>
                  🚀 <strong>Nuevas funcionalidades:</strong> Propuestas de
                  nuevas herramientas o módulos.
                </li>
              </ul>
            </li>
          </ul>
          <Button
            className="mt-4"
            type="primary"
            onClick={() => setShowForm(true)}
          >
            ¡Gracias! Continuemos...
          </Button>
        </Col>
      ) : (
        <>
          <Col span={10}>
            {submissionSuccess ? (
              <Result
                status="success"
                title="¡Sugerencia enviada con éxito!"
                subTitle="Gracias por tu aporte. Pronto será revisado."
                extra={[
                  <Button
                    key={"button"}
                    type="primary"
                    onClick={() => setSubmissionSuccess(false)}
                  >
                    Deacuerdo, enviar otra sugerencia
                  </Button>,
                ]}
              />
            ) : (
              <Card title="Nueva sugerencia">
                <br />
                <FormComponent
                  form={form}
                  formFields={SuggestionFormFields}
                  handleSubmit={handleSubmit}
                />
              </Card>
            )}
          </Col>
          <Col span={14}>
            <div className="ant-card-head">
              <div className="ant-card-head-wrapper">
                <div className="ant-card-head-title ml-2">
                  Mis sugerencias compartidas
                </div>
              </div>
            </div>
            <div className="suggestion-container">
              <SuggestionList />
            </div>
          </Col>
        </>
      )}
    </Row>
  );
};
// <Card>
//     <FormComponent form={form} formFields={SuggestionFormFields} handleSubmit={handleSubmit}/>
// </Card>
