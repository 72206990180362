import { SideBarLayout } from "../components/layout/SideBarLayout.jsx";
import { MainContainerLayout } from "../components/layout/MainContainerLayout.jsx";
import { SuggestionAdminLogic } from "../logic/suggestionAdmin/SuggestionAdminLogic.jsx";

export const SuggestionAdminPage = () => {
  return (
    <SideBarLayout>
      <MainContainerLayout title={"Gestión de Sugerencias"}>
        <SuggestionAdminLogic />
      </MainContainerLayout>
    </SideBarLayout>
  );
};
