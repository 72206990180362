// src/logic/reports/referralSource/ReferralSourceLogic.jsx

import { useQuery } from "@tanstack/react-query";
import { Button, Card, Col, DatePicker, Empty, Row, Select } from "antd";
import { Pie } from "@ant-design/plots";
import { useCallback, useMemo, useState } from "react";
import { getReferralSourceStatistics } from "../../../api/AtheleService.jsx";
import { LoaderIconUtils } from "../../../utils/LoaderIconUtils.jsx";
import {
  ReferralSourceColumns,
  ReferralSourceNestedColumns,
} from "./ReferralSourceColumns.jsx";
import { DatePresets } from "../../../utils/DatePresets.jsx";
import dayjs from "dayjs";
import { TablesComponent } from "../../../components/TablesComponent.jsx";
import { referralSourceToExcel } from "./ReferralSourceToExcel.jsx";

const referralSourceOptions = [
  { label: "Publicidad en la calle", value: "Publicidad en la calle" },
  { label: "Redes sociales", value: "Redes sociales" },
  { label: "Recomendación de un amigo/familiar", value: "Recomendación" },
  { label: "Por un coach", value: "Coach" },
  { label: "Eventos presenciales", value: "Eventos presenciales" },
  { label: "Volantes o impresos", value: "Volantes o impresos" },
  { label: "Anuncios en radio o TV", value: "Anuncios en radio o TV" },
];

export const ReferralSourceLogic = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [referralSource, setReferralSource] = useState(null);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    referralSource: null,
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: ["referralSourceStatistics", filters],
    queryFn: () => getReferralSourceStatistics(filters),
  });

  const columns = ReferralSourceColumns;
  const nestedColumns = ReferralSourceNestedColumns;
  const chartData = useMemo(() => {
    return data?.data?.map((item) => ({
      type: item._id,
      value: item.count,
    }));
  }, [data]);

  const config = {
    appendPadding: 10,
    data: chartData || [],
    angleField: "value",
    colorField: "type",
    radius: 1,
    label: {
      text: "type",
      labelHeight: 28,
      content: "{name} ({percentage})",
    },
    legend: {
      color: {
        title: false,
        position: "right",
        rowPadding: 5,
      },
    },
    interactions: [{ type: "element-active" }],
  };

  const onDateChange = useCallback((dates, dateStrings) => {
    // if (dates) {
    setStartDate(dateStrings[0] ? dayjs(dateStrings[0]).toISOString() : null);
    setEndDate(dateStrings[1] ? dayjs(dateStrings[1]).toISOString() : null);
    // } else {
    //   setFilters({ startDate: null, endDate: null });
    // }
  }, []);

  const onReferralSourceChange = useCallback((value) => {
    setReferralSource(value);
  }, []);

  const applyFilters = useCallback(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate,
      endDate,
      referralSource,
    }));
  }, [startDate, endDate, referralSource]);

  const clearFilters = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
    setReferralSource(null);
    setFilters({
      startDate: null,
      endDate: null,
      referralSource: null,
    });
  }, []);

  if (isLoading) return <LoaderIconUtils isLoading={true} />;
  if (isError) return <h1>Error al cargar los datos</h1>;

  return (
    <>
      <Row className="mb-2" gutter={[16, 16]} align={"top"} justify={"center"}>
        <Col>
          <DatePicker.RangePicker
            placeholder={["Inicio", "Fin"]}
            presets={DatePresets}
            onChange={onDateChange}
            value={
              startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []
            }
          />
        </Col>
        <Col>
          <Select
            placeholder="Filtrar por referencia"
            options={referralSourceOptions}
            onChange={onReferralSourceChange}
            value={referralSource}
            allowClear
          />
        </Col>
        <Col>
          <Button
            type="primary"
            className={"bg-primary-700"}
            onClick={applyFilters}
          >
            Aplicar
          </Button>
        </Col>
        <Col>
          <Button onClick={clearFilters}>Limpiar</Button>
          {/* <Button onClick={() => onDateChange(null)}>Limpiar Filtro</Button> */}
        </Col>
        <Col>
          <Button type="primary" onClick={()=>referralSourceToExcel(data)} className={"bg-green-700"}>
            Exportar a Excel
          </Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]} align={"top"} justify={"center"}>
        <Col span={12}>
          <Card title="Distribución de Referencias">
            {chartData.length ? (
              <Pie {...config} />
            ) : (
              <Empty description="Sin resultados" />
            )}
            {/* <Pie {...config} /> */}
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Detalle de Referencias">
            <TablesComponent
              data={data?.data}
              columns={columns}
              nestedColumns={nestedColumns}
              expandable={true}
              rowKey={(record) => record._id}
              pagination={{ pageSize: 5 }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};
