export const SuggestionFormFields = [
    {
        name: 'title',
        label: 'Título',
        rules: [{
            required:true,
            message : "El título es rquerido"
        }],
        inputType: 'input'
    },
    {
        name: 'description',
        label: 'Descripción',
        rules: [{
            required:true,
            message : "La descripción es requedida"
        }],
        inputType: 'textarea'
    },
    {
        name: 'category',
        label: 'Categoría',
        rules: [{
            required:true,
            message : "La categoría es requerida"
        }],
        inputType: 'select',
        options: [
            { value: 'Interfaz', label: 'Interfaz' },
            { value: 'Mejora', label: 'Mejora' },
            { value: 'Corrección', label: 'Corrección' },
            { value: 'Otro', label: 'Otro' }
        ],
        initialValue: 'Interfaz'
    }
]