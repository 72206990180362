import { MainContainerLayout } from "../components/layout/MainContainerLayout"
import { SideBarLayout } from "../components/layout/SideBarLayout"
import { SuggestionLogic } from "../logic/suggestion/SuggestionLogic"

export const SuggestionPage = () =>{
    return(
        <SideBarLayout>
            <MainContainerLayout title={'Buzón de sugerencias'}>
                <SuggestionLogic/>
            </MainContainerLayout>
        </SideBarLayout>
    )

}