import { Row, Tag } from "antd";
import moment from "moment/moment";
import "moment/locale/es-mx";
moment.locale("es-mx");

export const ReferralSourceColumns = [
  {
    title: "Fuente de Referencia",
    dataIndex: "_id",
    key: "referral_source",
    align: "center",
    width: 200,

  },
  {
    title: "Cantidad de Usuarios",
    dataIndex: "count",
    key: "count",
    align: "left",
  },
];

export const ReferralSourceNestedColumns = [
  {
    title: "Username",
    key: "username",
    align: "center",
    width: 100,
    render: (_, record) => {
      return (
        <div className={"mt-2 pb-0"}>
          {record.items.map((item, index) => (
            <Row key={index} align={"middle"} justify={"center"} className="pb-2">
              <div>{item.username}</div>
            </Row>
          ))}
        </div>
      );
    },
  },
  {
    title: "Tutor",
    key: "tutors_name_one",
    ellipsis: true,
    align: "center",
    width: 250,
    render: (_, record) => {
      return (
        <div className={"mt-2 pb-0"}>
          {record.items.map((item, index) => (
            <Row key={index} align={"middle"} justify={"center"} className="pb-2">
              <div>{item.tutors_name_one || "N/A"}</div>
            </Row>
          ))}
        </div>
      );
    },
  },
  {
    title: "Email",
    key: "email",
    align: "center",
    width: 250,
    render: (_, record) => {
      return (
        <div className={"mt-2 pb-0"}>
          {record.items.map((item, index) => (
            <Row key={index} align={"middle"} justify={"center"} className="pb-2">
              <div>{item.email}</div>
            </Row>
          ))}
        </div>
      );
    },
  },
  {
    title: "Teléfono",
    key: "phone",
    align: "center",
    width: 150,
    render: (_, record) => {
      return (
        <div className={"mt-2 pb-0"}>
          {record.items.map((item, index) => (
            <Row key={index} align={"middle"} justify={"center"} className="pb-2">
              <div>{item.phone || "N/A"}</div>
            </Row>
          ))}
        </div>
      );
    },
  },
  {
    title: "Inscrito",
    key: "is_inscribed",
    align: "center",
    width: 120,
    render: (_, record) => {
      return (
        <div className={"mt-2 pb-0"}>
          {record.items.map((item, index) => (
            <Row key={index} align={"middle"} justify={"center"} className="pb-2">
              <Tag color={item.is_inscribed ? "green" : "red"}>
                {item.is_inscribed ? "Sí" : "No"}
              </Tag>
            </Row>
          ))}
        </div>
      );
    },
  },
  {
    title: "Atletas",
    key: "athletes_count",
    align: "center",
    width: 100,
    render: (_, record) => {
      return (
        <div className={"mt-2 pb-0"}>
          {record.items.map((item, index) => (
            <Row key={index} align={"middle"} justify={"center"} className="pb-2">
              <div>{item.athletes_count}</div>
            </Row>
          ))}
        </div>
      );
    },
  },
  {
    title: "Fecha de Creación",
    key: "created_at",
    align: "center",
    width: 200,
    render: (_, record) => {
      return (
        <div className={"mt-2 pb-0"}>
          {record.items.map((item, index) => (
            <Row key={index} align={"middle"} justify={"center"} className="pb-2">
              <Tag color={"blue"}>{moment(item.created_at).format("DD/MM/YYYY")}</Tag>
            </Row>
          ))}
        </div>
      );
    },
  },
];
