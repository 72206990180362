// src\logic\reports\ReportsTabItems.jsx

import { AthletesEnrichedLogic } from "./athletesEnriched/AthletesEnrichedLogic.jsx";
import { AthletesPaymentsReportLogic } from "./athletePayments/AthletesPaymentsReportLogic.jsx";
import { ReferralSourceLogic } from "./referralSource/ReferralSourceLogic.jsx";

export const ReportsTabItems = [
  {
    key: "1",
    label: "Reporte de Ingresos",
    component: AthletesEnrichedLogic,
  },
  {
    key: "2",
    label: "Reporte de Estatus de Pago",
    component: AthletesPaymentsReportLogic,
  },
  {
    key: "3",
    label: "Reporte de Referencias",
    component: ReferralSourceLogic,
  },
];
