import { Tag, Button, Popconfirm } from "antd";
import { LoaderIconUtils } from "../../utils/LoaderIconUtils.jsx";
import { useAdminSuggestions } from "./SuggestionAdminMutations.jsx";
import { TablesComponent } from "../../components/TablesComponent.jsx";

export const SuggestionAdminLogic = () => {
  const { data, isLoading, updateStatus } = useAdminSuggestions();

  const handleStatusUpdate = async (record) => {
    console.log("Intentando actualizar sugerencia:", record);

    console.log("Intentando actualizar sugerencia con ID:", record._id);
    await updateStatus({ id: record._id, status: "Revisada" });
    console.log("Sugerencia actualizada con éxito");
  };

  const columns = [
    { title: "Título", dataIndex: "title", key: "title" },
    {
      title: "Categoría",
      dataIndex: "category",
      key: "category",
      render: (text) => <Tag color="geekblue">{text}</Tag>,
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <Tag color={text === "Pendiente" ? "gold" : "green"}>{text}</Tag>
      ),
    },
    {
      title: "Usuario",
      dataIndex: ["created_by", "username"],
      key: "created_by",
    },
    {
      title: "Acciones",
      key: "actions",
      render: (_, record) =>
        record.status === "Pendiente" && (
          <Popconfirm
            title="¿Estás seguro de cambiar el estado?"
            onConfirm={async () => handleStatusUpdate(record)}
          >
            <Button type="primary">Marcar como Revisada</Button>
          </Popconfirm>
        ),
    },
  ];

  if (isLoading) return <LoaderIconUtils isLoading />;

  return <TablesComponent data={data} columns={columns} />;
};
